import React from 'react';

const CallToAction = () => {
  return (
    <section className="why-choose-us">
      <h2>Hire The Best Digital Marketing Services
      </h2>
      <p>
      Want to make your webpage stand out? Hire the best digital marketing help, like Sparkdenz, to get it noticed! They’ll bring more visitors to your site, grow your audience, and boost your sales. With easy methods like improving your search rank, running social media ads, and sending emails, Sparkdenz gets people coming fast. No need to stress they handle it all, from writing fun content to reaching the right crowd. It’s affordable, works great, and saves you time. Ready to grow your webpage? Contact Sparkdenz today and see your business take off!</p>
    </section>
  );
};

export default CallToAction;