import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <a href="/">Sparkdenz</a>
        </div>
        <ul className={`nav-links ${isMobile ? 'active' : ''}`}>
          <li><a href="/">Home</a></li>
          <li><a href="/importance-of-digital-marketing">Blog</a></li>
          <li><a href="/social-bookmarking-sites">Bookmark</a></li>
          <li><a href="/#">Contact</a></li>
        </ul>
        <div className="hamburger" onClick={handleToggle}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;