import React from 'react';
import './App.css';
import Navbar from './Navbar';
import Card from './Card';
import CallToAction from './CallToAction';  // Importing the new CallToAction component
import WhyChooseUs from './WhyChooseUs';    // Importing the new WhyChooseUs component

const App = () => {
  return (
    <div>
      <Navbar />
      <div className="landing-page">
        {/* Hero Section */}
        <section className="hero-section">
          <div className="hero-content">
            <h1>Digital Brand Building Agency</h1>
            <p>Your solution to all your needs.</p>
            <button className="cta-button">Get Started</button>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <WhyChooseUs />  {/* Use the WhyChooseUs component here */}

        {/* Why Choose Us - Cards Section */}
        <section className="why-choose-us">
          <h2>Our Services</h2>
          <div className="card-container">
            <Card title="Search Engine Optimization (SEO)" description="Sparkdenz boosts your webpage’s rank on search engines, driving more visitors to your site. With smart SEO tricks, they make sure customers find you easily and fast!" />
            <Card title="Social Media Marketing (SMM)" description="Sparkdenz grows your audience with fun social media ads and posts. We target the right people, helping your business shine online and connect with more customers." />
            <Card title="Web Design" description="Sparkdenz creates eye-catching, easy-to-use websites that grab attention. Our designs fit your style, keep visitors hooked, and make your business look awesome online." />
            <Card title="Web Development" description="Sparkdenz builds fast, smooth websites that work perfectly. From simple pages to big projects, we handle the tech stuff so your site runs great for everyone." />
            <Card title="Content Management" description="Sparkdenz crafts cool content and keeps your site fresh. We write, update, and organize everything, making sure your webpage stays engaging and helpful for visitors." />
          </div>
        </section>

        {/* Call to Action Section */}
        <CallToAction />  {/* Use the CallToAction component here */}

        {/* Footer Section */}
        <footer className="footer">
          <p>&copy; 2025 MyWebsite. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default App;