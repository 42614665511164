import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us">
      <h2>Why Choose Us</h2>
      <p>
        Why pick Sparkdenz? We stay sharp and aligned with current market standards, pushing your business to new heights. Our skilled team offers clever, modern strategies to expand your online reach, draw in customers, and increase sales. Count on Sparkdenz to keep you competitive and make your growth easy!
      </p>
    </section>
  );
};

export default WhyChooseUs;